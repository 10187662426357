import styled from "styled-components";
import distances from "src/assets/styles/distances.js";

import mediaQuery from "src/assets/styles/mediaQuery";

const MainWrapper = styled.div`
  margin: 0 ${distances.pageMarginBig}rem;
  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  position: relative;
  height: 100%;
  @media (max-width: ${mediaQuery.laptop}) {
    margin: 0 30rem;
    width: calc(100% - 60rem);
  }
`;

export default MainWrapper;
