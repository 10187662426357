import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes.js";

const Paragraph = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: "Urbanist", sans-serif;
  line-height: 1.5;
`;

export default Paragraph;
