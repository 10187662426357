const personData = [
  {
    name: "3xhuman",
    avatar:
      "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/827.png",
    twitter: "https://twitter.com/3xhuman",
    quotePl: "By rozpalić innych musisz spalić siebie hahahahhahhhh",
    quoteEn: "To ignite other, you need to burn yourself hahahahahh",
    projects: ["Axie Infinity", "thePolacy"],
    location: "own-matrix",
    numerologicSign: "9",
  },
  {
    name: "Simon",
    avatar:
      "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/689.png",
    quotePl: "... wczytywanie",
    quoteEn: "loading ...",
    projects: ["next.js", "theSocial"],
    location: "internet",
    numerologicSign: "4",
  },
  {
    name: "Exhto",
    avatar: 
      "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/200.png",
    instagram: "https://instagram.com/exhto",
    quotePl: "yoga, vegan",
    quoteEn: "yoga, vegan",
    projects: ["Vegen Way of Life", "thePolacy"],
    location: "happiness",
    numerologicSign: "4",
  },
  {
    name: "Ozzy",
    avatar:
      "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/792.png",
    twitter: "https://twitter.com/filt_mr",
    quotePl: "jesteś, znaczy działać",
    quoteEn: "to be, is to act",
    projects: ["N project", "thePolacy"],
    location: "personal-domain",
    numerologicSign: "7",
  },
  {
    name: "Kojak",
    avatar:
      "https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/367.png",
    twitter: "https://twitter.com/rafalkonieczny_",
    quotePl: "lubisz to...",
    quoteEn: "you like it...",
    projects: ["Deplhy Records", "thePolacy"],
    location: "internet",
    numerologicSign: "4",
  },
  {
    name: "you?",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
    // twitter: "...",
    quotePl: "Twój własny cytat..",
    quoteEn: "your quote.",
    projects: ["Ethereum", "Polkadot"],
    location: "randomness",
    numerologicSign: "0",
  },
];

export default personData;
