import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: 'Urbanist', sans-serif;
        color: ${colors.dark};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
       
        @media (min-width: 1440px) {
            /* font-size: 0.0695vw; */

            }
    }

    /* html{scroll-behavior: smooth;} */
    ::selection {
        color: ${colors.white};
        background: ${colors.dark};
;
}
html {width: 100%; } /* Force 'html' to be viewport width */

 body{
     overflow-x: hidden;
     width: 100vw;
     font-size:  16rem ; 
 }
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
