import * as React from "react";
import styled from "styled-components";
import HorizontalScroll from "react-scroll-horizontal";
import MainWrapper from "src/components/global/MainWrapper.js";
import GlobalStyle from "src/assets/styles/globalStyles.js";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "src/assets/svg/ds-dao-logo.inline.svg";
import colors from "src/assets/styles/colors.js";
import star from "src/assets/svg/star_half.svg";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Paragraph from "src/components/global/Paragraph.js";
import Card from "src/components/Card.js";
import ContextConsumer from "src/layouts/Context.js";
import personData from "src/assets/data.js";
import Seo from "src/components/global/Seo.js";

const Wrapper = styled.main`
  width: 100vw;
  height: 100vh;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    height: auto;
  }
`;

const ImgWrapper = styled.div`
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover absolute !important;
  div {
    height: 100%;
    width: 100%;
  }
`;

const Shadow = styled.div`
  background-image: linear-gradient(
    to left bottom,
    #d563ff,
    #d688ff,
    #d9a7ff,
    #e0c3ff,
    #eadeff,
    #dbd2ee,
    #ccc7dc,
    #bebbcb,
    #908ba5,
    #645e80,
    #39345d,
    #0d0e3b
  );
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  position: absolute;
  left: -10rem;
  top: -10rem;
  z-index: -1;
  filter: blur(10rem);
  opacity: 0.7;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    opacity: 0.2;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :hover {
    transform: scale(0.99);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    ${Shadow} {
      opacity: 0.4;
      transform: scale(0.99);
      transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
    margin-top: 50rem;
  }
`;

const Nav = styled.nav`
  position: fixed;
  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  height: 100rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% - 60rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    width: 100%;
    ${ButtonWrapper} {
      display: none;
    }
  }
`;

const Button = styled.a`
  display: inline-block;
  height: 50rem;
  padding: 0 30rem;
  line-height: 50rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  text-decoration: none;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.dark};
  border: solid 3rem ${colors.dark};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.white};
    background-color: ${colors.darkGreen};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 10rem;
    margin-left: 0rem;
  }
  span {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
    position: relative;
    top: -2px;
    color: inherit;
    font-weight: 900;
  }
`;
const StyledLogo = styled(Logo)`
  width: 300rem;
  fill: white;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 220rem;
  }
`;

const MainLineWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  ::before {
    content: "";
    position: absolute;
    left: 0rem;
    bottom: 50%;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const MainLine = styled.span`
  height: 3rem;
  width: 100%;
  display: block;
  background-color: ${colors.white};
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100vh;
  .scrollWrapper > div {
    align-items: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ContentWrapperMobile = styled(MainWrapper)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
    padding-top: 50rem;
    padding-bottom: 200rem;
  }
`;

const InfoWrapper = styled.div`
  width: calc(50vw - ${distances.pageMarginBig}rem);
  margin-left: ${distances.pageMarginBig}rem;

  position: relative;
  & > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 60vw;
    margin-left: 30rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0rem;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 100rem;
    & > div {
      position: relative;
      top: 0%;
      transform: translateY(0%);
    }
  }
`;

const BigTitle = styled.h1`
  font-size: ${typographySizes.l}rem;
  font-family: "DM Serif Display", serif;
  color: ${colors.white};
  position: absolute;
  width: 66.66%;
  bottom: 0;

  margin-bottom: 30rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 90%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    position: relative;
    width: 100%;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: ${colors.white};
  margin-top: 30rem;
  position: absolute;
  width: 66.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 90%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    width: 100%;
  }
`;

const We3 = styled.p`
  position: absolute;
  bottom: 50rem;
  font-size: 12rem;
  color: ${colors.white};
  left: ${distances.pageMarginBig}rem;
  a {
    font-size: inherit;
    color: inherit;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    left: 30rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    color: ${colors.dark};
  }
`;

const IndexPage = () => {
  return (
    <Wrapper>
      <Seo
        title="deep space"
        description=" ZINE o Internetach. Kultura, Krypto, Konspira."
      />
      <ImgWrapper>
        <StaticImage
          className="img"
          objectFit="cover"
          src="../assets/images/bg.png"
          placeholder="blurred"
          alt="zdjęcie w tle"
        />
      </ImgWrapper>

      <GlobalStyle />
      <MainWrapper>
        <Nav>
          <StyledLogo />
          <ButtonWrapper>
              <Button href="https://zine.deepspace.lol">ZINE</Button>
          </ButtonWrapper>
        </Nav>
      </MainWrapper>
      <MainLineWrapper>
        <MainLine />
      </MainLineWrapper>
      <ContentWrapper>
        <ContextConsumer>
          {({ data, set }) => (
            <HorizontalScroll
              reverseScroll={true}
              className="scrollWrapper"
              disabled={data.boxHover}
            >
              <InfoWrapper>
                <div>
                  <BigTitle>
                    Zrelaksuj Twoje ciało i&nbsp;przygotuj Swój umysł.
                  </BigTitle>
                  <StyledParagraph>
                    ZINE o Internetach. Kultura, Krypto, Konspira.
                  </StyledParagraph>
                </div>
              </InfoWrapper>

              {personData.map((person) => (
                <>
                  <Card
                    name={person.name}
                    avatar={person.avatar}
                    projects={person.projects}
                    twitter={person.twitter}
                    instagram={person.instagram}
                    linkedin={person.linkedin}
                    discord={person.discord}
                    quotePl={person.quotePl}
                    quoteEn={person.quoteEn}
                    location={person.location}
                    numerologicSign={person.numerologicSign}
                  />
                </>
              ))}
            </HorizontalScroll>
          )}
        </ContextConsumer>
      </ContentWrapper>

      <ContentWrapperMobile>
        <InfoWrapper>
          <div>
            <BigTitle>
              Zrelaksuj Twoje ciało i&nbsp;przygotuj Swój umysł.
            </BigTitle>
            <StyledParagraph>
              ZINE o Internetach. Kultura, Krypto, Konspira.
            </StyledParagraph>
            <ButtonWrapper>
              <Button href="https://zine.deepspace.lol">ZINE</Button>
            </ButtonWrapper>
          </div>
        </InfoWrapper>
        {personData.map((person) => (
          <>
            <Card
              name={person.name}
              avatar={person.avatar}
              projects={person.projects}
              twitter={person.twitter}
              instagram={person.instagram}
              linkedin={person.linkedin}
              discord={person.discord}
              quotePl={person.quotePl}
              quoteEn={person.quoteEn}
              location={person.location}
              numerologicSign={person.numerologicSign}
            />
          </>
        ))}
      </ContentWrapperMobile>

      <We3>
        projekt i wdrożenie :{" "}
        <a href="https://we3studio.pl/" target="_blank">
          we3studio.pl
        </a>
      </We3>
    </Wrapper>
  );
};

export default IndexPage;
